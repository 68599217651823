export const questions2: string[][] = [
    ["出雲橋西詰", "いずもばしにしづめ"],
    ["今宮門前", "いまみやもんまえ"],
    ["上賀茂橋西詰", "かみがもばしにしづめ"],
    ["烏丸紫明", "からすましめい"],
    ["北大路大宮", "きたおおじおおみや"],
    ["北大路新町", "きたおおじしんまち"],
    ["北野白梅町", "きたのはくばいちょう"],
    ["木辻馬代", "きつじばだい"],
    ["衣笠北天神森町", "きぬがさきたてんじんもりちょう"],
    ["建勲神社前", "けんくんじんじゃまえ"],
    ["大将軍", "たいしょうぐん"],
    ["馬代一条", "ばだいいちじょう"],
    ["堀川今宮", "ほりかわいまみや"],
    ["堀川玄以", "ほりかわげんい"],
    ["御薗橋通大宮", "みそのばしどおりおおみや"],
    ["御薗橋通船岡東", "みそのばしどおりふなおかひがし"],
    ["川端東一条", "かわばたひがしいちじょう"],
    ["白川通北大路", "しらかわどおりきたおおじ"],
    ["白川通北山", "しらかわどおりきたやま"],
    ["東山近衛", "ひがしやまこのえ"],
    ["東山東一条", "ひがしやまいちじょう"],
    ["東山丸太町", "ひがしやままるたまち"],
    ["今出川新町", "いまでがわしんまち"],
    ["今出川智恵光院", "いまでがわちえこういん"],
    ["烏丸下立売", "からすましもだちうり"],
    ["烏丸下長者町", "からすましもちょうじゃまち"],
    ["烏丸中立売", "からすまなかだちうり"],
    ["千本鞍馬口", "せんぼんくらまぐち"],
    ["千本寺之内", "せんぼんてらのうち"],
    ["堀川寺之内", "ほりかわてらのうち"],
    ["堀川中立売", "ほりかわなかだちうり"],
    ["丸太町智恵光院", "まるたまちちえこういん"],
    ["油小路東寺道", "あぶらのこうじとうじみち"],
    ["大宮東寺道", "おおみやとうじみち"],
    ["大宮八条", "おおみやはちじょう"],
    ["葛野大路九条", "かどのおおじくじょう"],
    ["葛野大路八条", "かどのおおじはちじょう"],
    ["烏丸針小路", "からすまはりこうじ"],
    ["烏丸札ノ辻", "からすまふだのつじ"],
    ["河原町十条", "かわらまちじゅうじょう"],
    ["吉祥院西ノ茶屋", "きっしょういんにしのちゃや"],
    ["九条油小路", "くじょうあぶらのこうじ"],
    ["九条七本松", "くじょうしちほんまつ"],
    ["九条旧千本", "くじょうきゅうせんぼん"],
    ["九条新千本", "くじょうしんせんぼん"],
    ["九条新町", "くじょうしんまち"],
    ["九条西洞院", "くじょうにしのとういん"],
    ["久世橋烏丸", "くぜばしからすま"],
    ["十条油小路", "じゅうじょうあぶらのこうじ"],
    ["十条大宮", "じゅうじょうおおみや"],
    ["十条烏丸", "じゅうじょうからすま"],
    ["十条旧千本", "じゅうじょうきゅうせんぼん"],
    ["十条新千本", "じゅうじょうしんせんぼん"],
    ["十条新町", "じゅうじょうしんまち"],
    ["竹田街道十条", "たけだかいどうじゅうじょう"],
    ["竹田街道八条", "たけだかいどうはちじょう"],
    ["西大路九条", "にしおおじくじょう"],
    ["西大路十条", "にしおおじじゅうじょう"],
    ["五条春日", "ごじょうかすが"],
    ["天神川御池", "てんじんがわおいけ"],
    ["烏丸丸太町", "からすままるたまち"],
    ["烏丸北大路", "からすまきたおおじ"],
    ["烏丸鞍馬口", "からすまくらまぐち"],
    ["烏丸今出川", "からすまいまでがわ"],
    ["烏丸五条", "からすまごじょう"],
    ["烏丸七条", "からすましちじょう"],
    ["堀川北大路", "ほりかわきたおおじ"],
    ["北大路下鴨本通", "きたおおじしもがもほんどおり"],
    ["千本丸太町", "せんぼんまるたまち"],
    ["千本北大路", "せんぼんきたおおじ"],
    ["四条大宮", "しじょうおおみや"],
    ["東山三条", "ひがしやまさんじょう"],
    ["四条室町", "しじょうむろまち"],
    ["寺町五条", "てらまちごじょう"],
    ["千本御池", "せんぼんおいけ"],
    ["千本松原", "せんぼんまつばら"],
    ["千本五条", "せんぼんごじょう"],
    ["四条河原町", "しじょうかわらまち"],
    ["葛野大路五条", "かどのおおじごじょう"],
    ["川端御池", "かわばたおいけ"],
    ["川端二条", "かわばたにじょう"],
    ["北白川別当", "きたしらかわべっとう"],
    ["荒神橋東詰", "こうじんばしひがしづめ"],
    ["下鴨本通北大路", "しもがもほんどおりきたおおじ"],
    ["百万遍", "ひゃくまんべん"],
    ["大宮木津屋橋", "おおみやきづやばし"],
    ["大宮七条", "おおみやしちじょう"],
    ["烏丸御池", "からすまおいけ"],
    ["河原町御池", "かわらまちおいけ"],
    ["河原町八条", "かわらまちはちじょう"],
    ["五条七本松", "ごじょうしちほんまつ"],
    ["五条御前", "ごじょうおんまえ"],
    ["四条西洞院", "しじょうにしのとういん"],
    ["四条堀川", "しじょうほりかわ"],
    ["七条壬生", "しちじょうみぶ"],
    ["堀川高辻", "ほりかわたかつじ"],
    ["西大路花屋町", "にしおおじはなやまち"],
    ["九条大宮", "くじょうおおみや"],
    ["中山稲荷", "なかやまいなり"],
    ["八条壬生", "はちじょうみぶ"]
]