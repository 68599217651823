import React from 'react'

const ResutlSrc = () => {
    return (
        <div className='resultkanban'>
            <img src="./result.svg" alt="結果" />
        </div>
    )
}

export default ResutlSrc